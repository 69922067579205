var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active-user-container"},[_c('div',{staticClass:"admin-header"},[_c('div',{staticClass:"top-header"},[_c('div',{staticClass:"top-left"},[_c('v-tabs',{staticStyle:{"height":"46px"},on:{"change":_vm.onSelectedTab},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{attrs:{"value":"0"}},[_c('v-badge',{attrs:{"color":"red","content":_vm.totalNumberOfItems}},[_vm._v("All")])],1),_c('v-tab',{attrs:{"value":"1"}},[_c('v-badge',{attrs:{"color":"red","content":_vm.totalNumberOfCandidateItems}},[_vm._v("Candidate")])],1),_c('v-tab',{attrs:{"value":"2"}},[_c('v-badge',{attrs:{"color":"red","content":_vm.totalNumberOfRepItems}},[_vm._v("Representative")])],1)],1)],1),_c('div',{staticClass:"top-right"},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mr-2",staticStyle:{"background-color":"rgb(42 205 100)","color":"#fff"},attrs:{"m":""},on:{"click":_vm.onSearch}},[_vm._v(" Search ")])],1)])]),_c('div',{staticClass:"panel-content"},[_c('v-data-table',{staticClass:"elevation-1 dt-table",attrs:{"show-select":"","items":_vm.items,"headers":_vm.headers,"single-select":false,"search":_vm.search,"item-key":"name","server-items-length":_vm.totalNumberOfItems,"options":_vm.options,"loading":_vm.loading,"loading-text":'Loading... Please wait',"footer-props":{
        'items-per-page-text': 'Show',
      }},on:{"update:options":_vm.paginate},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value},[(header.text !== 'actions')?_c('span',[_vm._v(" "+_vm._s(header.text))]):_vm._e(),(header.text == 'actions')?_c('span',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("item 1")])],1)],1)],1)],1):_vm._e()])}),0)]}},{key:"body",fn:function(ref){
      var items = ref.items;
return _vm._l((items),function(item){return _c('tr',{key:item.name,on:{"click":function (e) { return _vm.onItemClick(item); }}},[_c('td',[_c('v-checkbox',{staticStyle:{"margin":"0px","padding-left":"16px"},attrs:{"value":item.isChecked,"hide-details":""}})],1),_c('td',{staticClass:"id"},[_vm._v(_vm._s(item["id"]))]),_c('td',{staticClass:"created_at"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item["created_at"]))+" ")]),_c('td',{staticClass:"full_name"},[_vm._v(_vm._s(item["full_name"]))]),_c('td',{staticClass:"account_type"},[_vm._v(" "+_vm._s(item["account_type"] == 1 ? "Candidate" : item["account_type"] == 2 ? "Representative" : "")+" ")]),_c('td',{staticClass:"account_type"},[_vm._v(" "+_vm._s(item["account_type"] == 1 && item.candidate_info ? item.candidate_info.data_input_status == 0 ? "In-complete" : item.candidate_info.data_input_status > 5 && item.candidate_info.is_uplaoded_doc == 1 ? "Fully Completed" : item.candidate_info.data_input_status > 5 && item.candidate_info.is_uplaoded_doc == 0 ? "Completed Without ID" : "Partially Completed" : item["account_type"] == 2 && item.representative_info ? item.representative_info.data_input_status == 0 ? "In-complete" : item.representative_info.data_input_status > 2 && item.representative_info.is_uplaoded_doc == 1 ? "Fully Completed" : item.representative_info.data_input_status > 2 && item.representative_info.is_uplaoded_doc == 0 ? "Completed" : "Partially Completed" : "In-completed")+" ")]),_c('td',{staticClass:"status"},[_c('span',{style:({color: _vm.getStatusColor(item)})},[_vm._v(_vm._s(_vm.getStatus(item)))])]),_c('td',{staticClass:"email"},[_vm._v(" "+_vm._s(item["email"])+" ")]),_c('td',{staticClass:"Actions"},[_c('div',[(item.account_type === 1)?_c('router-link',{attrs:{"to":'/admin/user_candidate_details/' +
                  item.id +
                  '?type=' +
                  item.account_type}},[_c('v-btn',{staticStyle:{"background-color":"#522e8e","color":"#fff"},attrs:{"small":""}},[_vm._v(" view ")])],1):_c('router-link',{attrs:{"to":'/admin/user_candidate_details/' +
                  item.id +
                  '?type=' +
                  item.account_type}},[_c('v-btn',{staticStyle:{"background-color":"#522e8e","color":"#fff"},attrs:{"small":""}},[_vm._v(" view ")])],1)],1)])])})}}]),model:{value:(_vm.selectedTasks),callback:function ($$v) {_vm.selectedTasks=$$v},expression:"selectedTasks"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }